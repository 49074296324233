import React, { useState } from 'react'
import styles from './Pricing.module.css'
import ScrollAnimation from '../Animation/ScrollAnimation'
import CombineAnimation from '../../components/Animation/CombineAnimation'

const Pricing = () => {
    const [selectedPlan, setselectedPlan] = useState('Value')

    const handlePayment = async (amount) => {
        const merchantKey = '5AR0055' // Replace with your actual merchant key
        const clientKey = '0oaxb9i8P9vQdXTsn3l5' // Replace with your actual client key

        // Construct the payment request payload
        const paymentRequest = {
            merchantKey: merchantKey,
            clientKey: clientKey,
            amount: amount, // Convert amount to cents (SecurePay expects amount in cents)
            currency: 'AUD', // Adjust currency as needed
            // Add any additional parameters as required by SecurePay API
        }

        console.log('Sending payment request:', paymentRequest)
        try {
            const response = await fetch(
                'https://cors-anywhere.herokuapp.com/https://api.securepay.com.au/xmlapi/payment',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(paymentRequest),
                }
            )

            console.log('Payment request response:', response)

            if (response.ok) {
                // Payment request successful
                const responseData = await response.json()
                // Check if payment URL is available in response
                if (responseData.paymentUrl) {
                    // Redirect user to the payment gateway URL
                    window.location.href = responseData.paymentUrl
                } else {
                    console.error('Payment URL not found in response.')
                }
            } else {
                // Payment request failed
                console.error('Payment request failed:', response.statusText)
            }
        } catch (error) {
            // Handle network errors or other exceptions
            console.error('Error sending payment request:', error)
        }
    }
    return (
        <div className={styles.Pricing}>
            <div className={styles.Heading}>
                {/* <img src={star} alt="start" /> */}
                <ScrollAnimation>
                    <h4>PLANS AND PRICING</h4>
                </ScrollAnimation>
            </div>
            <ScrollAnimation>
                <h1>AFFORDABLE PRICING. </h1>
            </ScrollAnimation>
            <ScrollAnimation>
                <h1>EASY SCALING.</h1>
            </ScrollAnimation>
            <div className={styles.SelectionDiv}>
                <button
                    onClick={() => setselectedPlan('Value')}
                    className={
                        selectedPlan === 'Value'
                            ? styles.activePlan
                            : styles.notActive
                    }
                >
                    Value Plans
                </button>
                <button
                    onClick={() => setselectedPlan('Power')}
                    className={
                        selectedPlan === 'Power'
                            ? styles.activePlan
                            : styles.notActive
                    }
                >
                    Power Plans
                </button>
                <button
                    onClick={() => setselectedPlan('Ecommerce')}
                    className={
                        selectedPlan === 'Ecommerce'
                            ? styles.activePlan
                            : styles.notActive
                    }
                >
                    Ecommerce Plans
                </button>
            </div>
            {selectedPlan === 'Value' && (
                <div className={styles.PricingDetails}>
                    <CombineAnimation>
                        <div
                            className={`${styles.LeftSection} ${styles.LeftSection1}`}
                        >
                            <div className={styles.PriceCircle}>
                                <p>$699</p>
                                <p> One-Time</p>
                            </div>
                            <div className={styles.PriceText}>
                                <h2>Strategy & Planning</h2>
                                <p>Value Plan for your new business</p>
                            </div>
                        </div>

                        <div className={styles.RightSection}>
                            <ul>
                                <li>5-7 Pages</li>
                                <li>Unlimited revisions</li>
                                <li>Static</li>
                                <li>Responsive Layout</li>
                                <li>5 webmail account</li>
                                <li>Basic S.E.O.</li>
                                <li>Free website update</li>
                                <li>500MB web space</li>
                                <li>Bandwidth 99.9% uptime guaranteed</li>
                                <li>CPanel</li>
                                <li>24X7 Email support</li>
                                <li>Free domain</li>
                            </ul>

                            <button
                                className={styles.btn}
                                id={styles.btn1}
                                onClick={() => handlePayment(699)}
                            >
                                Buy Now
                            </button>
                        </div>
                    </CombineAnimation>
                </div>
            )}
            {selectedPlan === 'Power' && (
                <div className={styles.PricingDetails}>
                    <CombineAnimation>
                        <div
                            className={`${styles.LeftSection} ${styles.LeftSection2}`}
                        >
                            <div className={styles.PriceCircle}>
                                <p>$899</p>
                                <p> One-Time</p>
                            </div>
                            <div className={styles.PriceText}>
                                <h2>Premium & Planning</h2>
                                <p>Power Plan to boost you business</p>
                            </div>
                        </div>
                        <div className={styles.RightSection}>
                            <ul>
                                <li>15+ Dynamic Pages</li>
                                <li>Unlimited revisions</li>
                                <li>CMS</li>
                                <li>Responsive Layout</li>
                                <li>10 webmail account</li>
                                <li>Basic S.E.O.</li>
                                <li>Free website update</li>
                                <li>1GB web space</li>
                                <li>Bandwidth 99.9% uptime guaranteed</li>
                                <li>CPanel</li>
                                <li>24X7 Email support</li>
                                <li>Free domain</li>
                            </ul>

                            {/* <button className={styles.btn} id={styles.btn1}>Buy Now</button> */}
                            {/* <form action="https://www.paypal.com/cgi-bin/webscr" className={styles.PriceButton} method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="DA27HX2YBCC64" />

                                <input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_buynow_SM.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" style={{ width: "100px" }} />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_AU/i/scr/pixel.gif" width="1" height="1" />
                            </form> */}
                            <button
                                className={styles.btn}
                                id={styles.btn1}
                                onClick={() => handlePayment(899)}
                            >
                                Buy Now
                            </button>
                        </div>
                    </CombineAnimation>
                </div>
            )}
            {selectedPlan === 'Ecommerce' && (
                <div className={styles.PricingDetails}>
                    <CombineAnimation>
                        <div
                            className={`${styles.LeftSection} ${styles.LeftSection3}`}
                        >
                            <div className={styles.PriceCircle}>
                                <p>$1600</p>
                                <p> One-Time</p>
                            </div>
                            <div className={styles.PriceText}>
                                <h2>Unlimited & Planning</h2>
                                <p>
                                    Ecommerce Plan to reach your client's home
                                </p>
                            </div>
                        </div>
                        <div className={styles.RightSection}>
                            <ul>
                                <li> Unlimited Dynamic Pages</li>
                                <li>Unlimited revisions</li>
                                <li>Ecommerce</li>
                                <li>Responsive Layout</li>
                                <li>15 webmail account</li>
                                <li>Basic S.E.O.</li>
                                <li>Free website update</li>
                                <li>2GB web space</li>
                                <li>Bandwidth 99.9% uptime guaranteed</li>
                                <li>CPanel</li>
                                <li>24X7 Email support</li>
                                <li>Free domain</li>
                            </ul>

                            {/* <button className={styles.btn} id={styles.btn1}>Buy Now</button> */}
                            {/* <form action="https://www.paypal.com/cgi-bin/webscr" className={styles.PriceButton} method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="8H7DDYF59NBZL" />

                                <input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_buynow_SM.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" style={{ width: "100px" }} />
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_AU/i/scr/pixel.gif" width="1" height="1" />
                            </form> */}

                            {/* <div style={{
                                overflow: "auto", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center", width: "259px", background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.1)", boxShadow: " -2px 10px 5px rgba(0, 0, 0, 0)",
                                borderRadius: "10px",
                                fontFamily: " Domine, SQ Market, Helvetica, Arial, sans-serif"
                            }}>
                                <div style={{ padding: "20px" }}>
                                    <a target="_blank" href="https://square.link/u/iuslMBp7?src=embed" style={{
                                        display: "inline-block",
                                        fontSize: "18px",
                                        lineHeight: "48px",
                                        height: "48px",
                                        color: "#ffffff",
                                        minWidth: "212px",
                                        backgroundColor: "#2693ff",
                                        textAlign: "center",
                                        boxShadow: " 0 0 0 1px rgba(0,0,0,.1) inset",
                                        borderRadius: "50px"
                                    }}>Buy now</a>
                                </div>

                            </div> */}
                            <button
                                className={styles.btn}
                                id={styles.btn1}
                                onClick={() => handlePayment(1600)}
                            >
                                Buy Now
                            </button>
                        </div>
                    </CombineAnimation>
                </div>
            )}
        </div>
    )
}

export default Pricing
