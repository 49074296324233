import React, { useState, useEffect, useRef } from 'react'
import { useLocation, Link, NavLink } from 'react-router-dom'
import NavbarAnimation from './Animation/NavbarAnimation'
import styles from './Navbar.module.css'
// import * as FaIcons from "react-icons/fa6";
import * as AiIcons from 'react-icons/ai'
import * as BsIcons from 'react-icons/bs'
import * as IoIcons from 'react-icons/io5'
import * as BiIcons from 'react-icons/bi'
import { IconContext } from 'react-icons'
import Logo from '../img/Logos/primary_logo.png'
import menu from '../img/Flaticon/menu1.webp'
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs'
import { useInView } from 'framer-motion'
// import { createPortal } from 'react-dom'

const Navbar = () => {
    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const location = useLocation()

    let locationString1 = location.pathname.slice(1)
    const shouldShowNavbar = locationString1.includes('StrategySession')

    const ref = useRef()
    const isInView = useInView(ref)

    const [uparrow, setuparrow] = useState(false)

    useEffect(() => {
        if (isInView) {
            setuparrow(true)
        }
    }, [isInView, uparrow])
    useEffect(() => {
        let locationString = location.pathname.slice(1)
        if (!locationString) {
            locationString = 'home'
        }
        const str2 =
            locationString.charAt(0).toUpperCase() + locationString.slice(1)
        // const str2 = locationString.toUpperCase();
        let titleString = "Murphy's Technology - " + str2
        document.title = titleString
    }, [location.pathname])

    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [dropdownHelp, setDropdownHelp] = useState(false)
    const [dropdownwho, setDropdownwho] = useState(false)

    // eslint-disable-next-line
    const handleDropdownCclick = (e) => {
        setDropdownVisible(!dropdownVisible)
        if (dropdownVisible) {
            e.target.classList.add(styles.color)
        } else {
            e.target.classList.remove(styles.color)
        }
    }
    // eslint-disable-next-line
    const handleClickHelp = (e) => {
        setDropdownHelp(!dropdownHelp)
        if (dropdownHelp) {
            e.target.classList.add(styles.color)
        } else {
            e.target.classList.remove(styles.color)
        }
    }
    // eslint-disable-next-line
    const handleClickWho = (e) => {
        setDropdownwho(!dropdownwho)
        if (dropdownwho) {
            e.target.classList.add(styles.color)
        } else {
            e.target.classList.remove(styles.color)
        }
    }

    const changecolor = () => {
        const scrollbarDiv = document.querySelector('#ScrollBar')
        if (scrollbarDiv) {
            if (window.scrollY > 200 && scrollbarDiv) {
                scrollbarDiv.classList.add(styles.ShowOnScroll)
            } else {
                scrollbarDiv.classList.remove(styles.ShowOnScroll)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changecolor)
        return () => {
            window.removeEventListener('scroll', changecolor)
        }
    }, [])

    return (
        <>
            {!shouldShowNavbar && (
                <div className={styles.topHeader}>
                    <header>
                        <div className={styles.TopBar}>
                            <IconContext.Provider value={{ size: '20px' }}>
                                <div className={styles.LeftSection}>
                                    <div>
                                        <BsIcons.BsFillPersonFill
                                            style={{ marginRight: '5px' }}
                                        />{' '}
                                        <a href="https://client.murphystechnology.com.au/login/">
                                            Customers Login
                                        </a>{' '}
                                    </div>
                                    <div>
                                        <IoIcons.IoCall
                                            style={{ marginRight: '5px' }}
                                        />{' '}
                                        <a href="tel:0272544827">
                                            Call Now 02 7254 4827 |
                                        </a>
                                        <a href="tel:0485931114">
                                            &nbsp; 0485 931 114
                                        </a>
                                    </div>
                                </div>
                                <div className={styles.RightSection}>
                                    <a
                                        href="https://www.instagram.com/murphystechnology/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AiIcons.AiOutlineInstagram />
                                    </a>
                                    <a
                                        href="https://www.facebook.com/murphysworld80"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <BiIcons.BiLogoFacebook />
                                    </a>
                                    {/* <AiIcons.AiOutlineInstagram /> */}
                                    {/* <BiIcons.BiLogoLinkedin /> */}
                                    {/* <BiIcons.BiLogoFacebook /> */}
                                    {/* <AiIcons.AiFillYoutube /> */}
                                </div>
                            </IconContext.Provider>
                        </div>
                        <nav className={`${styles.topmenu} ${styles.fixed}`}>
                            <div className={styles.navbar}>
                                <a
                                    href="tel:0272544827"
                                    style={{ marginLeft: '5px' }}
                                    className={`${styles.menubars} ${styles.navbaranchor}`}
                                >
                                    <BsIcons.BsTelephoneFill />
                                </a>
                                <div className={styles.logo}>
                                    <NavLink to="/">
                                        <img src={Logo} alt="logo" />
                                    </NavLink>
                                </div>
                                <div className={styles.text}>
                                    <a
                                        href="https://murphystechnology.com.au/"
                                        className={
                                            location.pathname === '/'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        HOME
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/about"
                                        className={
                                            location.pathname.slice(1) ===
                                            'about'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        ABOUT
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/services"
                                        className={
                                            location.pathname.slice(1) ===
                                            'services'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        SERVICES
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/affiliates"
                                        className={
                                            location.pathname.slice(1) ===
                                            'affiliates'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        AFFILIATES
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/reviews"
                                        className={
                                            location.pathname.slice(1) ===
                                            'reviews'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        REVIEWS
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/process"
                                        className={
                                            location.pathname.slice(1) ===
                                            'process'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        PROCESS
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/career"
                                        className={
                                            location.pathname.slice(1) ===
                                            'career'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        CAREER
                                    </a>

                                    <div className={styles.dropdownContainer}>
                                        <a
                                            className={
                                                location.pathname.slice(1) ===
                                                    'contact' ||
                                                location.pathname.slice(1) ===
                                                    'FAQ'
                                                    ? `${styles.navitem} ${styles.navitemactive}`
                                                    : styles.navitem
                                            }
                                        >
                                            HELP
                                        </a>
                                        <div className={styles.dropdownContent}>
                                            <a
                                                href="https://murphystechnology.com.au/contact"
                                                className={styles.dropdownItem}
                                            >
                                                CONTACT
                                            </a>
                                            <a
                                                href="https://murphystechnology.com.au/FAQ"
                                                className={styles.dropdownItem}
                                            >
                                                FAQ
                                            </a>
                                        </div>
                                    </div>
                                    <a
                                        href="https://client.murphystechnology.com.au/login/"
                                        className={`${styles.navitem} ${styles.btn} `}
                                        id={styles.btn1}
                                    >
                                        Sign up
                                    </a>
                                </div>

                                <a href="#" className={styles.menubars}>
                                    {/* <FaIcons.FaBars onClick={showSidebar} /> */}
                                    <img
                                        src={menu}
                                        alt="menu"
                                        onClick={showSidebar}
                                    />
                                </a>
                            </div>
                        </nav>

                        <nav className={styles.ScrollBar} id="ScrollBar">
                            <div className={styles.navbarScroll}>
                                <a
                                    href="tel:0272544827"
                                    className={styles.menubars}
                                >
                                    <BsIcons.BsTelephoneFill />
                                </a>
                                <div className={styles.logo}>
                                    <NavLink href="/">
                                        <img src={Logo} alt="logo" />
                                    </NavLink>
                                </div>
                                <div className={styles.text}>
                                    <a
                                        href="/"
                                        className={
                                            location.pathname === '/'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        HOME
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/about"
                                        className={
                                            location.pathname.slice(1) ===
                                            'about'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        ABOUT
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/services"
                                        className={
                                            location.pathname.slice(1) ===
                                            'services'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        SERVICES
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/affiliates"
                                        className={
                                            location.pathname.slice(1) ===
                                            'affiliates'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        AFFILIATES
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/reviews"
                                        className={
                                            location.pathname.slice(1) ===
                                            'reviews'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        REVIEWS
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/process"
                                        className={
                                            location.pathname.slice(1) ===
                                            'process'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        PROCESS
                                    </a>
                                    <a
                                        href="https://murphystechnology.com.au/career"
                                        className={
                                            location.pathname.slice(1) ===
                                            'career'
                                                ? `${styles.navitem} ${styles.navitemactive}`
                                                : styles.navitem
                                        }
                                    >
                                        CAREER
                                    </a>

                                    <div className={styles.dropdownContainer}>
                                        <a
                                            className={
                                                location.pathname.slice(1) ===
                                                    'contact' ||
                                                location.pathname.slice(1) ===
                                                    'FAQ'
                                                    ? `${styles.navitem} ${styles.navitemactive}`
                                                    : styles.navitem
                                            }
                                        >
                                            HELP
                                        </a>
                                        <div className={styles.dropdownContent}>
                                            <a
                                                href="https://murphystechnology.com.au/contact"
                                                className={styles.dropdownItem}
                                            >
                                                CONTACT
                                            </a>
                                            <a
                                                href="https://murphystechnology.com.au/FAQ"
                                                className={styles.dropdownItem}
                                            >
                                                FAQ
                                            </a>
                                        </div>
                                    </div>
                                    <a
                                        href="https://client.murphystechnology.com.au/login/"
                                        className={`${styles.navitem} ${styles.btn} `}
                                        id={styles.btn1}
                                    >
                                        Sign up
                                    </a>
                                </div>
                                <a href="" className={styles.menubars}>
                                    {/* <FaIcons.FaBars onClick={showSidebar} /> */}
                                    <img
                                        src={menu}
                                        alt="menu"
                                        onClick={showSidebar}
                                    />
                                </a>
                            </div>
                        </nav>

                        <nav
                            className={`${
                                sidebar
                                    ? `${styles.navmenu} ${styles.active}`
                                    : styles.navmenu
                            }`}
                        >
                            <ul
                                className={styles.navMenuItems}
                                style={{ scrollBehavior: 'smooth' }}
                            >
                                {!uparrow && sidebar && (
                                    <div className={styles.downArrow}>
                                        <a
                                            href="#Follow"
                                            alt="linkdown"
                                            onClick={() => setuparrow(true)}
                                        >
                                            <BsArrowDownCircle />
                                        </a>
                                    </div>
                                )}
                                {uparrow && sidebar && (
                                    <div className={styles.upArrow}>
                                        <a
                                            href="#Top"
                                            alt="linkdown"
                                            onClick={() => setuparrow(false)}
                                        >
                                            <BsArrowUpCircle />
                                        </a>
                                    </div>
                                )}
                                <li className={styles.navbarToggle} id="Top">
                                    <IconContext.Provider
                                        value={{ color: 'white' }}
                                    >
                                        <a
                                            href="#"
                                            className={styles.menubars}
                                            onClick={showSidebar}
                                        >
                                            <AiIcons.AiOutlineClose />
                                        </a>
                                    </IconContext.Provider>
                                </li>
                                <li
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: '1.5em',
                                    }}
                                >
                                    <img
                                        className={styles.logo}
                                        src={Logo}
                                        alt="logo"
                                    />
                                </li>
                                <div className={styles.ReadyToChat}>
                                    <h2>Get a Quote?</h2>
                                    <button>
                                        <a href="https://client.murphystechnology.com.au/login/">
                                            Login/Signup
                                        </a>
                                    </button>
                                </div>
                                <div className={styles.container}>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a href="/" onClick={showSidebar}>
                                                <div
                                                    className={
                                                        styles.ScrollNavItem1
                                                    }
                                                >
                                                    <h3>Home</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/about"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem2
                                                    }
                                                >
                                                    <h3>About</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/services"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem3
                                                    }
                                                >
                                                    <h3>Services</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/affiliates"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem4
                                                    }
                                                >
                                                    <h3>Affiliates</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/reviews"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem5
                                                    }
                                                >
                                                    <h3>Reviews</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/process"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem6
                                                    }
                                                >
                                                    <h3>Process</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/career"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem7
                                                    }
                                                >
                                                    <h3>Career</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/contact"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem8
                                                    }
                                                >
                                                    <h3>Contact</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <a
                                                href="https://murphystechnology.com.au/FAQ"
                                                onClick={showSidebar}
                                            >
                                                <div
                                                    className={
                                                        styles.ScrollNavItem9
                                                    }
                                                >
                                                    <h3 id="Follow">FAQ</h3>
                                                </div>
                                            </a>
                                        </NavbarAnimation>
                                    </div>
                                </div>
                                {/* <div className={`${styles.ReadyToChat} ${styles.follow}`}>
                                    <h2>
                                        Follow Us !!
                                    </h2>
                                    <div className={styles.NavSocial}>
                                        <a href="https://www.instagram.com/murphystechnology/" id="Follow" ref={ref} target="_blank" rel="noreferrer"><AiIcons.AiOutlineInstagram /></a>
                                        <a href="https://www.facebook.com/murphysworld80" target="_blank" rel="noreferrer" ><BiIcons.BiLogoFacebook /></a>
                                    </div>
                                </div> */}
                            </ul>
                        </nav>
                    </header>
                </div>
            )}
        </>
    )
}

export default Navbar
