import React from 'react'
import HeroSection from '../../components/Home/HeroSection'
import AboutSection from '../../components/Home/AboutSection'
import MarqueeSection from '../../components/Home/MarqueeSection'
import Pricing from '../../components/Home/Pricing'
import Contactus from '../../components/Home/Contactus'
import Testimonial from '../../components/Home/Testimonial'
import GetAQuote from '../../components/GetAQuote'
import Scalling from '../../components/Home/Scalling'
import MobileApp from '../../components/Home/MobileApp'
import Banner from '../../components/Home/Banner'
import { Helmet } from 'react-helmet'
// import FAQ from '../../components/FAQ'

const Home = () => {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Sydney Web Design Company: Expert Solutions for Online
                    Success
                </title>
                <meta
                    name="description"
                    content="Unlock online success with our Sydney Web Design Company. Expert solutions tailored for your brand. Elevate your online presence with creativity and innovation."
                />
                <meta name="keywords" content="Sydney web design company" />
            </Helmet>
            <div style={{ backgroundColor: 'white' }}>
                <HeroSection />
                <AboutSection />
                <Banner />
                <Scalling />
                <MobileApp />
                <MarqueeSection />
                <Pricing />
                <Contactus />
                <Testimonial />
                <GetAQuote />
                {/* <FAQ /> */}
            </div>
        </>
    )
}

export default Home
