import React, { useState, useRef, useEffect } from 'react'
import styles from './AboutSection.module.css'
import Team from '../../img/Flaticon/team.webp'
import Creative from '../../img/Flaticon/creativity.webp'
import Customer from '../../img/Flaticon/customer-service.webp'
import Services from '../../img/Flaticon/higher.webp'
import image1 from '../../img/Home/Component 7.webp'
import curves from '../../img/Flaticon/curvedAarrow.webp'
import Line from '../../img/Flaticon/Line2.webp'
import { useNavigate } from 'react-router'
import ScrollAnimation from '../Animation/ScrollAnimation'
// import ConfettiExplosion from 'react-confetti-explosion'
import Confetti from 'react-dom-confetti'
import { motion, useInView } from 'framer-motion'

const AboutSection = () => {
    const navigate = useNavigate()
    const config = {
        angle: '108',
        spread: '360',
        startVelocity: '37',
        elementCount: '135',
        dragFriction: '0.19',
        duration: '2500',
        stagger: 3,
        width: '10px',
        height: '10px',
        perspective: '509px',
        colors: ['#FF0000', '#0000FF', '#FFFF00', '#FF00FF', '#00FF00'],
    }
    const [isExploding, setIsExploding] = useState(false)
    const ref = useRef()
    const isInView = useInView(ref, { once: true })
    useEffect(() => {
        if (isInView) {
            setIsExploding(true)
            // setIsExplodingTop(true)
        }
        // eslint-disable-next-line
    }, [isInView])
    return (
        <div className={styles.body}>
            <motion.div
                className={styles.LeftSection}
                initial={{ opacity: 0, x: -75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: 'linear',
                    type: 'spring',
                    duration: 5,
                    bounce: 0.4,
                }}
            >
                {/* <div className={styles.homeVideo}>
                    <iframe
                        title="video1"
                        src="https://cdn.vidyard.com/thumbnails/42766543/BX9DgKQtMuzZbn760WnkuZ6fewbV1K3u.gif"
                        frameBorder="0"
                        width="100%"
                        height="100%"
                        allowFullScreen
                        allow="autoplay"
                        mute="1"
                        className={styles.homeVideo}
                        style={{ borderRadius: '20px' }}
                    ></iframe>
                </div> */}
                <div>
                    <img src={image1} alt="imag1" />
                </div>
                <div className={styles.experienceDiv}>
                    <span>15+</span>
                    <p> Years Of Experience</p>
                </div>
                <div className={styles.CurveDiv}>
                    <img src={curves} alt="imag1" />
                </div>
                <div className={styles.LineDiv}>
                    <img src={Line} alt="imag1" />
                </div>
            </motion.div>

            <motion.div
                className={styles.RightSection}
                initial={{ opacity: 0, x: 75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: 'linear',
                    type: 'spring',
                    duration: 5,
                    staggerChildren: 0.5,
                }}
            >
                <div className={styles.topConfettie}>
                    <Confetti active={isExploding} config={config} />
                </div>
                <ScrollAnimation>
                    <h4>GROW 10X FASTER , BETTER, SMARTER</h4>
                </ScrollAnimation>

                <ScrollAnimation>
                    <h1>MURPHYS</h1>
                </ScrollAnimation>
                <ScrollAnimation>
                    <h1> TECHNOLOGY</h1>
                </ScrollAnimation>
                <p>
                    Murphys Technology is a full-service web design and
                    development agency that specializes in inexpensive website
                    design and development, ecommerce websites, SEO, logo
                    design, and online marketing solutions. We deal with
                    businesses all throughout Australia. We're a web design and
                    development agency that's been entrusted by some of the
                    world's most well-known businesses.
                </p>
                <div className={styles.gridContainer}>
                    <div className={styles.gridItem}>
                        <div className={styles.icons}>
                            <img src={Customer} alt="creative" />
                        </div>
                        <div className={styles.text} ref={ref}>
                            <p>Best Customer Support</p>
                        </div>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.icons}>
                            <img src={Creative} alt="creative" />
                        </div>
                        <div className={styles.text}>
                            <p>Creative Designers</p>
                        </div>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.icons}>
                            <img src={Services} alt="creative" />
                        </div>
                        <div className={styles.text}>
                            <p>Great Services</p>
                        </div>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.icons}>
                            <img src={Team} alt="creative" />
                        </div>
                        <div className={styles.text}>
                            <p>Professional Team</p>
                        </div>
                    </div>
                </div>

                <button
                    className={styles.btn}
                    id={styles.btn1}
                    onClick={() => navigate('/contact')}
                >
                    Contact Us
                </button>
            </motion.div>
        </div>
    )
}

export default AboutSection
