import React, { useState, useEffect, useRef } from 'react'
// import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './ActualCareer.module.css'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'
// import axios from 'axios';
import GetAQuote from '../../components/GetAQuote'

import emailjs from "@emailjs/browser";
import TopBanner2 from '../../components/TopBanner/TopBanner2'
import { Helmet } from "react-helmet";

const ActualCareer = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [randomNumber, setRandomNumber] = useState(null);
    const [mathvalue, setmathValue] = useState();
    const [isCaptchaCorrect, setisCaptchaCorrect] = useState(false);
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_eadi7g8",
                "template_uei05j1",
                form.current,
                "JTlkQK4Xi-xCiim0_"
            )
            .then(
                (result) => {
                    // console.log(result.text);
                    alert("Message has been sent!");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    const handleSubmitEmail = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        sendEmail(e);
        form.current.reset();
    };

    const handlemath = (e) => {
        const newValue = e.target.value;
        setmathValue(newValue);

        // Now, use the updated mathvalue to calculate isCaptchaCorrect
        const isCorrect = newValue === `${3 + randomNumber}`;
        setisCaptchaCorrect(isCorrect);
    }

    const generateRandomNumber = () => {
        const min = 1; // Minimum value
        const max = 100; // Maximum value
        const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
        setRandomNumber(randomInt);
    }
    useEffect(() => {
        generateRandomNumber();

    }, []);
    const onFileChange = (event) => {
        const file = event.target.files[0];

        // Check if the selected file is a PDF
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
        } else {
            // Display an error message or clear the selected file
            alert('Please select a PDF file.');
            event.target.value = null; // Clear the file input field
            setSelectedFile(null); // Clear the selected file state
        }
    };

    const onFileUpload = () => {
        const formData = new FormData();
        formData.append('myFile', selectedFile, selectedFile.name);
        console.log(selectedFile);
        // axios.post('api/uploadfile', formData);
    };
    const fileData = () => {
        if (selectedFile) {
            return (
                <div>
                    <p>File Name: {selectedFile.name}</p>
                    <p>File Type: {selectedFile.type}</p>
                    {/* <p>Last Modified: {selectedFile.lastModifiedDate.toDateString()}</p> */}
                </div>
            );
        } else {
            return (
                <div>
                    <h5>Choose a pdf file before Pressing the Upload button</h5>
                </div>
            );
        }
    };
    return (
        <>
        <Helmet>
        <meta charset="utf-8" />
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
            <div className={styles.outerDiv}>
                {/* <TopBanner name="Career" /> */}
                <TopBanner2 name="Career" />
                <div className={styles.careerdiv}>
                    <ScrollAnimation>
                        <h4>GROW 10X FASTER , BETTER, SMARTER</h4>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1>Apply for</h1>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <h1> Career at Murphy's</h1>
                    </ScrollAnimation>
                    <div className={styles.Form}>

                        <form ref={form} onSubmit={(e) => handleSubmitEmail(e)} >

                            <div className={styles.gridContainer}>
                                <div className={styles.column}>
                                    <label>First Name<span>*</span></label>
                                    <input type="text" name="Fname" placeholder='First Name' />
                                </div>
                                <div className={styles.column}>
                                    <label>Last Name<span>*</span></label>
                                    <input type="text" name="Lname" placeholder='Last Name' />
                                </div>

                                <div className={styles.column}>
                                    <label>Email<span>*</span></label>
                                    <input type="email" name="Email" placeholder='example@example.com' /></div>
                                <div className={styles.column}>
                                    <label>Phone Number<span>*</span></label>
                                    <input type="text" name="Phone" placeholder='0400 000 000' />
                                </div>
                                <div className={`${styles.column} ${styles.span2}`}>
                                    <label>Preferred<span>*</span></label>
                                    <select name="Post" id="post">
                                        <option value="Sales Trainee">Sales Trainee</option>
                                        <option value="BackEnd Developer">Backend Developer</option>
                                        <option value="FrontEnd Developer">Frontend Developer</option>
                                        <option value="Full Stack Developer">Full Stack Developer</option>
                                    </select>
                                </div>
                                <div className={styles.column}>
                                    <label>Expertise<span>*</span></label>
                                    <input type="text" name="Expertise" placeholder='Eg. React' /></div>
                                <div className={styles.column}>
                                    <label>Total Experience(in months)<span>*</span></label>
                                    <input type="text" name="Experience" placeholder='Eg. 4 months' />
                                </div>

                                <div className={`${styles.column} ${styles.span2}`}>
                                    <label>Cover Letter<span>*</span></label>
                                    <textarea rows='10' name="CoverLetter" placeholder=''></textarea>
                                </div>
                                <div className={`${styles.column} ${styles.span2}`}>
                                    <label>Resume<span>*</span></label>
                                    <div className={styles.upload}>

                                        <input type="file" name="file" onChange={onFileChange} />
                                        <button className={styles.btn} id={styles.btn1} onClick={onFileUpload}>Upload!</button>
                                    </div>
                                    {fileData()}
                                </div>
                                <div className={`${styles.column} ${styles.span2}`}>
                                    <label>Math question<span>*</span></label>
                                    <p>3 + {randomNumber} =</p>
                                    <input value={mathvalue} onChange={(e) => handlemath(e)} placeholder=''></input>
                                    <small>Solve this simple math problem and enter the result. E.g. for 1+3, enter 4.</small>
                                </div>
                                <div className={`${styles.column} ${styles.span2}`}>
                                    <button disabled={!isCaptchaCorrect} className={styles.btn} id={styles.btn1} type='submit'>Apply Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <GetAQuote />
        </>
    )
}

export default ActualCareer
